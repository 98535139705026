<div dougsModalTitle>
  <h6>{{ data.title }}</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <dougs-form-field [noMargin]="true">
    <label dougsFormFieldLabel>Raison</label>
    <textarea autosize dougsFormFieldControl [(ngModel)]="message"></textarea>
  </dougs-form-field>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
  <dougs-button color="primary" (click)="validateMessage()">Valider</dougs-button>
</div>
