import { Injectable } from '@angular/core';

import { concatMap, lastValueFrom, Observable, take } from 'rxjs';
import { CompletionReason, CompletionReasonCode } from '@dougs/accounting-review/dto';
import { COMPLETION_REASONS, VisaStateService } from '@dougs/accounting-review/shared';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { ModalService, SelectModalComponent } from '@dougs/ds';
import { PartnerStateService } from '@dougs/partners/shared';
import { LabftSurveyModalComponent, MissionAcceptationModalComponent } from '@dougs/surveys/ui';
import { CompletionReasonModalComponent } from '../../modals/completion-reason-modal/completion-reason-modal.component';

@Injectable({
  providedIn: 'root',
})
export class CharteredAccountantComponentService {
  isApplyingVisa = false;

  refreshForeignPartners$: Observable<void> = this.companyStateService.activeCompany$.pipe(
    concatMap((activeCompany) =>
      this.partnerStateService.refreshForeignPartners(activeCompany.id, ['isAssociate', 'isCeo', 'isDirector']),
    ),
  );

  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly partnerStateService: PartnerStateService,
    private readonly modalService: ModalService,
    private readonly visaStateService: VisaStateService,
  ) {}

  openLabftSurvey(accountingYear: AccountingYear | null): void {
    this.modalService.open(LabftSurveyModalComponent, {
      data: {
        accountingYear,
      },
    });
  }

  openMissionAcceptation(): void {
    this.modalService.open(MissionAcceptationModalComponent, {
      data: {
        modifyOnly: true,
      },
    });
  }

  async applyVisa(accountingYear: AccountingYear): Promise<void> {
    if (!this.isApplyingVisa) {
      this.isApplyingVisa = true;

      const selectedOption = await this.selectVisaOption();

      if (selectedOption) {
        const completionReason: CompletionReason | undefined = COMPLETION_REASONS.find(
          (reason) => reason.code === selectedOption,
        );

        if (completionReason) {
          const newCompletionReason = { ...completionReason };
          let message = newCompletionReason.label;
          if (newCompletionReason.messageRequired) {
            message = (await this.selectCompletionReason(newCompletionReason)) || '';

            if (!message) {
              this.isApplyingVisa = false;
              return await this.applyVisa(accountingYear);
            }

            newCompletionReason.label = message;
          }

          await this.visaStateService.applyVisa(accountingYear, {
            ...newCompletionReason,
            label: message,
          });
        }
      }

      this.isApplyingVisa = false;
    }
  }

  private async selectCompletionReason(completionReason: CompletionReason): Promise<string | null | undefined> {
    return (
      await lastValueFrom(
        this.modalService
          .open<string>(CompletionReasonModalComponent, {
            data: {
              title: completionReason.messageTitle || 'Merci de détailler la raison',
            },
          })
          .afterClosed$.pipe(take(1)),
      )
    ).data;
  }

  private async selectVisaOption(): Promise<CompletionReasonCode | null | undefined> {
    return (
      await lastValueFrom(
        this.modalService
          .open<CompletionReasonCode>(SelectModalComponent, {
            data: {
              title: 'Appliquer / refuser mon visa',
              hideSearch: true,
              items: COMPLETION_REASONS,
            },
          })
          .afterClosed$.pipe(take(1)),
      )
    ).data;
  }
}
