import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutosizeModule } from 'ngx-autosize';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  FormFieldComponent,
  LabelFormFieldDirective,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
} from '@dougs/ds';

@Component({
  selector: 'dougs-completion-reason-modal',
  standalone: true,
  imports: [
    CommonModule,
    ModalTitleDirective,
    ModalFooterDirective,
    ModalContentDirective,
    ModalCloseDirective,
    ButtonComponent,
    FormFieldComponent,
    ControlFormFieldDirective,
    LabelFormFieldDirective,
    AutosizeModule,
    FormsModule,
  ],
  templateUrl: './completion-reason-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompletionReasonModalComponent {
  message = '';

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      title: string;
    },
    private readonly modalRef: ModalRef,
  ) {}

  validateMessage(): void {
    this.modalRef.close(this.message);
  }
}
